<!--  -->
<template>
  <div class='fapiaoDel round bj-col-white pad'>
    <h4>发票信息</h4>
    <div>
      <span>状态：</span>
      <div style="color: #09B38A;">{{info.state}}</div>
    </div>
    <div>
      <span>年份：</span>
      <div>{{$route.query.year}}</div>
    </div>
    <div>
      <span>发票开具方式：</span>
      <div>(普通发票)电子</div>
    </div>
    <div>
      <span>发票金额：</span>
      <div>180元</div>
    </div>
    <div>
      <span>发票内容：</span>
      <div>培训费</div>
    </div>
    <div>
      <span>发票抬头：</span>
      <div>{{info.gongsi}}</div>
    </div>
    <div>
      <span>社会统一信用代码：</span>
      <div>{{info.shibiehao}}</div>
    </div>
    <!-- <div>
      <span>手机号：</span>
      <div>{{info.phone}}</div>
    </div> -->
    <div>
      <span>电子邮箱：</span>
      <div>{{info.email}}</div>
    </div>
    <div>
      <span>备注：</span>
      <p>{{info.beizhu}}</p>
    </div>
  </div>
</template>

<script>
import Api from '../../../api/my'

export default {
  components: {},
  data() {
    return {
      info: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getInvoice()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getInvoice () {
      Api.getInvoice({
        year: this.$route.query.year
      }).then(res => {
        if (res.data.code == 1) {
          this.info = res.data.data
        } else {
          this.$message.error("发票信息获取失败")
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.fapiaoDel {
  > div {
    margin-top: 30px;
    display: flex;
    align-items: center;
    > span {
      margin-right: 10px;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    p {
      width: 550px;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}
</style>